import APIService from "./APIService";



class AuthService extends APIService
{

   login = async ({email, password}:any, initial = false) => {
      const result = await this.post({email, password}, `auth/login${initial ? '?initial=true': ''}`);
        
      return result ? result.data : null;
   }

   fbLogin = async ({email, userID}:any, initial = false) => {
      const result = await this.post({email, userID}, `auth/fb-login${initial ? '?initial=true': ''}`);
        
      return result ? result.data : null;
   }

   googleLogin = async ({email, userID}:any, initial = false) => {
      const result = await this.post({email, userID}, `auth/google-login${initial ? '?initial=true': ''}`);
        
      return result ? result.data : null;
   }

   fbSignup = async ({email, userID, merchant_image, name}:any) => {
      const result = await this.post({email, userID, merchant_image, name}, `auth/fb-signup`);
        
      return result ? result.data : null;
   }

   googleSignup = async ({email, userID, merchant_image, name}:any) => {
      const result = await this.post({email, userID, merchant_image, name}, `auth/google-signup`);
        
      return result ? result.data : null;
   }

   forgotPassword = async ({email}:any) => {
      const result = await this.post({email}, 'auth/forgot-password');
        
      return result ? result.data : null;
   }

   resetPassword = async ({token, new_password, confirm_password}:any) => {
      const result = await this.post({token, new_password, confirm_password}, 'auth/reset-password');
        
      return result ? result.data : null;
   }

   isLoggedIn = () => {
      return localStorage.getItem('access_token') ? true : false;
   }

   isFullyRegistered = () => {
      const user_local =  localStorage.getItem('user') || "{}"
      const { merchant, is_fully_registered } = JSON.parse(user_local)
      return { merchant: merchant, is_fully_registered }
   }

   logout = async (user_id: any) => {
      const result = await this.post({user_id}, 'auth/logout');
      return result ? result.data : null;
   }

   test = async () => {
      const result = await this.get('test-api-call');

      return result ? result.data : null;
   }

   verifyUser = async (id: any) => {
      const result = await this.get(`auth/verify-user/${id}`)
      return result ? result.data : null;
   }

   verifyMerchant = async (token: any) => {
      const result = await this.get(`auth/verify-merchant/${token}`)
      return result ? result.data : null;
   }

   changePassword = async (id: any, formData: any) => {
      const result = await this.put(formData,`auth/${id}/change-password`)
      return result ? result.data : null;
   }

   register = async ({email, password}:any) => {
      const result = await this.post({email, password}, 'auth/register');
      return result ? result.data : null;
   }
   
}

export default AuthService