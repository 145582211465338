
/**
 * App configuration
 */
class APPConfig
{
    /**
     * api url endpoint
     * sample: http://localhost:3000/api
     */
    static API_URL:string = process.env.REACT_APP_API_ENDPOINT ? process.env.REACT_APP_API_ENDPOINT : 'https://sd-api.ark.ph/api';
    // static API_URL:string ='http://localhost:3001/api';
    // static API_URL:string = process.env.REACT_APP_API_ENDPOINT ? process.env.REACT_APP_API_ENDPOINT : 'http://localhost:3000/api';
    static DEFAULT_TIMEZONE:string = process.env.REACT_APP_DEFAULT_TIMEZONE ? process.env.REACT_APP_DEFAULT_TIMEZONE : 'Asia/Dubai';
    static SOCKET_URL:string = process.env.REACT_APP_API_SOCKET_URL ? process.env.REACT_APP_API_SOCKET_URL : 'https://gp-notif.ark.ph/';
    static FB_APP_ID:string = process.env.REACT_APP_FB_APP_ID ? process.env.REACT_APP_FB_APP_ID : "";
    static GOOGLE_CLIENT_ID:string = process.env.REACT_APP_GOOGLE_CLIENT_ID ? process.env.REACT_APP_GOOGLE_CLIENT_ID : "";
    static GOOGLE_SECRET_KEY:string = process.env.REACT_APP_GOOGLE_SECRET_KEY ? process.env.REACT_APP_GOOGLE_SECRET_KEY : "";
}

export default APPConfig;