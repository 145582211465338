/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import {useHistory, useParams } from 'react-router-dom';
import socketIOClient from 'socket.io-client'
import moment  from 'moment'

import { ClockCircleOutlined} from '@ant-design/icons'
import { Col, Row, Empty, Table } from 'antd'

import './NotificationsStyle.less'
import ManageNotificationService from '../../services/api/ManageNotificationService'

import APPConfig from '../../config'


const socket = socketIOClient(APPConfig.SOCKET_URL, { transports: ['websocket', 'polling', 'flashsocket'] })

const noDataPlaceholder = () => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'No Notification Found'} />

const NotificationsView = () => {
  const [notificationsData, setNotificationsData]: any = useState([])
  const manageNotificationService = new ManageNotificationService()
  
  useEffect(() => {
    loadResources()
    
    if (localStorage.getItem('user')) {
      const data:any = localStorage.getItem('user')
      const user = JSON.parse(data)
      socket.on('merchant_notifications_'+user?.merchant?._id, () => {
        loadResources()
      })
    } 

    return () => {
      socket.disconnect()
    }
  }, [])

  const loadResources = async (filter: any = '', page: any = 1, limit: any = 5) => {
    const result = await manageNotificationService.search(filter, page, limit)
    setNotificationsData(result.notifications)
    return result.notifications
  }

  const handleMarkAsRead = async (id: string) => {
    const notif = notificationsData.find((item: any) => item._id === id)
    if (!notif.read) {
      await manageNotificationService.markAsRead(id)
    }
    loadResources()
  }

  return (notificationsData || []).length ? (<>
    {
      (notificationsData || []).map((data: any) => {
        const {_id, title, description, createdAt, read, module_type, company_id } = data

        return (
          <Row key={_id} className={`notifications__header-list ${!read ? 'unread' : ''}`} onClick={()=> handleMarkAsRead(_id)}>
              <Col span={24}>
                <span className='title'>{module_type === 'Order' ? company_id.name : title}</span> 
                <span className="desc">{description}</span>
              </Col>
              <Col span={24} className='time-updated'>
                <ClockCircleOutlined />{moment(createdAt).fromNow()}
              </Col>
              { !read ? <span className='isRead'/> : null }
          </Row>
        )
      })
    }
  </>) : (
    <Table
      scroll={{ x: 768 }}
      className='tip-pagination max-width'
      rowClassName="text-secondary"
      locale={{ emptyText: noDataPlaceholder }}
    />
  )
}

export default React.memo(NotificationsView)