//@ts-nocheck
import React, { useState } from "react";
import { Layout } from 'antd';
import SideMenuView from "./component/sidebar/SideMenuView";
import HeaderView from "./component/header/HeaderView";



const { Sider, Content } = Layout;

const LayoutView = ({ children }:any) => {   

    const [collapsed, setCollapsed] = useState(window.innerWidth <= 768);
    const [pageHeader, setPageHeader] = useState('');

    const toggleCollapse = (collapsed: boolean) => {
        setCollapsed(collapsed);
    };
    const child =  React.Children.map(children, (child) => 
        {   
            return React.cloneElement(child, {setPageHeader: setPageHeader });
        });
    return (
        <Layout>
             <Sider width={250} theme="light" trigger={null} className="sidebar" collapsible collapsed={collapsed} onCollapse={toggleCollapse}>
                <SideMenuView siderCollapsed={collapsed} collapseToggle={toggleCollapse} style={{ background: '#ffffff'}}/>
            </Sider>
            <Layout className="main-layout">
                <HeaderView siderCollapsed={collapsed} collapseToggle={toggleCollapse} pageHeader={pageHeader} />
                <Content style={{
                        padding: 24,
                        minHeight: 'calc(100vh - 56px)',
                        maxHeight: 'calc(100vh - 56px)',
                        overflow: 'auto'
                    }}>
                        {child}
                </Content>
                {/* <FooterView /> */}
            </Layout>  
        </Layout>   
    );
}


export default LayoutView