import axios from 'axios';
import APPConfig from '../../config';

class APIService
{
    protected baseURL:string;
    
    constructor( url:string = '') {
        //set the default api url properly
        this.baseURL = url && url.length > 0 ? url : APPConfig.API_URL + "/merchants";
       
        let currentConfig:any = null;
        // Add a request interceptor
        axios.interceptors.request.use( async (config)  => {
            //get the token
            const token = localStorage.getItem('access_token');
            //get the current config so that we can call the method if there is a token expired error
            //do not get the config for refresh token call, because it means that it's refreshing the token
            if(this.baseURL + '/auth/refresh-token' !==  config.url)
                currentConfig = config;
            
            if(token && token.length > 0 && token !== 'undefined')
                config.headers.Authorization =  'Bearer ' + token;
            return config;
        });

        //add common handlers
        axios.interceptors.response.use((response) => {
            // do something with the response data
            
            return response;
          }, async (error) => {
            //handle errors here
            if (error.response) { 
                if(error.response.status === 401)
                {
                    //if the token has expired then we have to call the refresh token and call the method again
                    //const data = {access_token: localStorage.getItem('access_token'), refresh_token: localStorage.getItem('refresh_token')};
                    const data = {access_token: localStorage.getItem('access_token'), refresh_token: localStorage.getItem('refresh_token')};
                    const result = await axios.post(this.baseURL + '/auth/refresh-token', data);

                    //set the new access token
                    localStorage.setItem('access_token', result.data.access_token);
                    //call again the previous method
                    axios(currentConfig);
                } else{
                    return Promise.reject(error);
                }
            } else {
                return Promise.reject(error);
            }
          });
    }

    buildURL = (method:string):string => {
        return this.baseURL + '/' + method;
    }

    get = async (method:string) => {
        return await axios.get(this.buildURL(method));
    }

    getWithRequestBody = async (data: any, method:string) => {
      return await axios.get(this.buildURL(method), data);
    }

    postBlob = (data:any, method:string, filename:string) => {
        axios({
          url: this.buildURL(method), //your url
          method: 'post',
          responseType: 'blob', // important,
          data: data
        }).then((response) => {
           const url = window.URL.createObjectURL(new Blob([response.data]));
           const link = document.createElement('a');
           link.href = url;
           link.setAttribute('download', filename); //or any other extension
           document.body.appendChild(link);
           link.click();
        });
    }

    post = async (data:any, method:string) => {
        try
        {
            return await axios.post(this.buildURL(method), data);
        }catch(error){
            
            return Promise.reject(error);
        }
        
    }

    postWithoutRequestBody = async (method:string) => {
      try
      {
          return await axios.post(this.buildURL(method));
      }catch(error){
          
          return Promise.reject(error);
      }
      
    }
    
    put = async (data:any, method:string) => {
        return await axios.put(this.buildURL(method), data);
    }

    putWithoutRequestBody = async (method:string) => {
      return await axios.put(this.buildURL(method));
    }

    patch = async (data:any, method:string) => {
        return await axios.patch(this.buildURL(method), data);
    }

    patchWithoutRequestBody = async (method:string) => {
      return await axios.patch(this.buildURL(method));
    }

    remove = async (method:string) => {
        return await axios.delete(this.buildURL(method));
    }

    removeWithRequesthBody = async (method:string, data?: any) => {
      const config: any = {
        data,
        method: 'delete',
        url: this.buildURL(method)
      }
      return await axios(config)
    }





}

export default APIService