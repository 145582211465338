import React, { Component } from "react";
import "./App.css";
import axios from 'axios'
import Loader from "./components/Loader";
import lazyComponentLoader from './hoc/LazyLoader';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { isArray } from "util";
import DialogUtils from "./utils/DialogUtils";
import LayoutView from "./views/layout/LayoutView";
import PublicRoute from "./hoc/PublicRoute";
import ProtectedRoute from './hoc/ProtectedRoute';
import AuthService from "./services/api/AuthService";

interface IAppState {
  isLoading:Boolean
  errorVisible:Boolean
}
const authService = new AuthService();
const Login:any = lazyComponentLoader(() => import('./views/auth/login/LoginView'));
const Forgot:any = lazyComponentLoader(() => import('./views/auth/forgot/ForgotView'));
const Reset:any = lazyComponentLoader(() => import('./views/auth/reset/ResetView'));
const Register:any = lazyComponentLoader(() => import('./views/auth/register/RegisterView'));

//Merchant Profile Management
const CompleteRegistrationView:any = lazyComponentLoader(() => import('./views/auth/register/CompleteRegistrationView'));
const MerchantRegistrationVerified: any =lazyComponentLoader(() => import('./views/auth/register/MerchantRegistrationVerified'));

// NOTIFICATIONS VIEW
const Notifications: any = lazyComponentLoader(() => import('./views/notifications/NotificationsAllView'));


//DATE EXPERIENCE MANAGEMENT VIEW
const DateExperienceManagement: any = lazyComponentLoader(() => import('./views/date-experience-management/DateExperienceManagementView'))
const DateExperienceDetails: any = lazyComponentLoader(() => import('./views/date-experience-management/DateExperienceDetails'))

//RESERVATIONS MANAGEMENT VIEW
const ReservationsManagement: any = lazyComponentLoader(() => import('./views/reservations-management/ReservationsManagementView'))
const ReservationDetails: any = lazyComponentLoader(() => import('./views/reservations-management/ReservationDetails'))


//SALES MANAGEMENT VIEW
const SalesManagement: any = lazyComponentLoader(() => import('./views/sales-management/SalesManagementView'))

//RECEVIABLES MANAGEMENT VIEW
const ReceivablesManagement: any = lazyComponentLoader(() => import('./views/receivables-management/ReceivablesManagementView'))


//SETTINGS VIEW
const Settings: any = lazyComponentLoader(() => import('./views/settings/SettingsView'))
const EditSettings: any = lazyComponentLoader(() => import('./views/settings/EditSettingsView'))

class App extends Component<any, IAppState> {
  
  constructor(props:any) {
    super(props);
    //initialize state here
    this.state = {
        isLoading: false,
        errorVisible: true
    }

    const self = this;
    axios.interceptors.request.use(function (config) {
      // spinning start to show
      self.setState({isLoading: true});
      
      return config
    }, function (error) {
      return Promise.reject(error);
    });

    axios.interceptors.response.use(function (response) {
      // spinning hide
      self.setState({isLoading: false});
      
      return response;
    }, async (error) => {
      if (error.response) {
        this.showError(error.response.data.errors);
      } else {
        this.showError(error);
      }
      self.setState({isLoading: false});
      return Promise.reject(error);
    });
  }

  showError(errors:any)
  {
    let content:any = null;
    if(isArray(errors))
    {
  
      // message.error(
        content = (< div>{
          errors.map( (item:any, index:number) => { return <div key={Math.random().toString(6)}>{item.message}</div>})
          }
        </div>)
    // );
    } else {
     content = errors === undefined || errors.message.includes('Network Error') ? 'No network connection. Make sure that WI-FI or Cellular Mobile Data is turned on, then try again.' : errors.message;
    }
    // const err = content? content.split('Error: ') : '';
    DialogUtils.error(content);
    
  }
  
  render() {

    return (
      <div>
        <Router>
            <Switch>
              <PublicRoute 
              auth={{ isLoggedIn: () => authService.isLoggedIn() }}
              exact path='/login'
              component={Login}/>
              <PublicRoute 
              auth={{ isLoggedIn: () => authService.isLoggedIn() }}
              exact path='/reset-password/:token'
              component={Reset}/>
              <PublicRoute 
              auth={{ isLoggedIn: () => authService.isLoggedIn() }}
              exact path='/forgot-password'
              component={Forgot}/>
              <PublicRoute 
              auth={{ isLoggedIn: () => authService.isLoggedIn() }}
              exact path='/register'
              component={Register}/>

              <PublicRoute 
              auth={{ isLoggedIn: () => authService.isLoggedIn() }}
              exact path='/verify-merchant/:token'
              component={MerchantRegistrationVerified}/>
               
              {/* MERCHANT COMPLETE REGISTRATION */}
              <ProtectedRoute
                exact path={'/register/:id/complete-registration'} 
                component={CompleteRegistrationView} />
              

              <Route render={() => {
                const isLoggedIn = authService.isLoggedIn();
                const { merchant, is_fully_registered } = authService.isFullyRegistered();
                 

                /* REDIRECT TO COMPLETE REGISTRATION IF NOT FULLY REGISTERED */
                if(isLoggedIn && !is_fully_registered){
                  return <Redirect from='*' to={`register/${merchant._id}/complete-registration`} />
                }else if(!isLoggedIn){
                  return <Redirect from='*' to="/login" />
                }else{
                  return <LayoutView>


                          {/* DATE EXPERIENCE MANAGEMENT VIEW */}
                          <ProtectedRoute exact path={['/', '/date-experience-management']} component={DateExperienceManagement} />
                          <ProtectedRoute exact path={'/date-experience-management/:id'} component={DateExperienceDetails} />

                          {/* RESERVATIONS MANAGEMENT VIEW */}
                          <ProtectedRoute exact path={'/reservations-management'} component={ReservationsManagement} />
                          <ProtectedRoute exact path={'/reservations-management/bookings/:booking_id/reservations/:id'} component={ReservationDetails} />


                          {/* SALES MANAGEMENT VIEW */}
                          <ProtectedRoute exact path={'/sales-management'} component={SalesManagement} />

                           {/* RECEIVABLES MANAGEMENT VIEW */}
                           <ProtectedRoute exact path={'/receivables-management'} component={ReceivablesManagement} />

                          {/* NOTIFICATIONS VIEW */}
                          <ProtectedRoute exact path={'/notifications'} component={Notifications} />

                          {/* SETTINGS VIEW */}
                          <ProtectedRoute exact path={'/settings'} component={Settings} />
                          <ProtectedRoute exact path={'/edit-settings'} component={EditSettings} />

                          {/* REDIRECT TO HOME/LOGIN IF NO PAGE FOUND */}
                          <Route render={(props:any) => 
                            {
                              const { key }  = props.location;
                              const isLoggedIn = authService.isLoggedIn();

                              if (isLoggedIn && !key) {
                                return <Redirect from='*' to="/" />
                              } else if (!isLoggedIn) {
                                return <Redirect from='*' to="/login" />
                              }
                            }
                          } />
                          {/* END */}
                        </LayoutView>
                }
              }}/>

                  
            </Switch>
        </Router>
        
        {this.state.isLoading ? <Loader /> : null}
      </div>
    );  
  }
}

export default App;